import React from 'react'
import styled from 'styled-components'
import { color } from '../../utils'
import { screen } from '../../utils/screen'

const PointDescContainer = styled.div`
  width: 70%;
  padding-right: 100px;
  transition: all 2s;

  @media screen and (max-width: ${screen.mobile}) {
    width: 90%;
    padding: 0;
  }

  & > h3 {
    font-size: 30px;
    margin-bottom: 30px;
    line-height: 1.8;

    @media screen and (max-width: ${screen.mobile}) {
      text-align: center;
      font-size: 24px;
      font-weight: 900;
    }
  }

  & > div {
    line-height: 1.8;

    @media screen and (max-width: ${screen.mobile}) {
      font-size: 14px;
    }
  }

  & > button {
    background-color: ${color.black};
    color: ${color.white};
    border: 0;
    font-size: 18px;
    padding: 10px 20px;
    margin-top: 20px;

    @media screen and (max-width: ${screen.mobile}) {
      width: 100%;
      font-size: 16px;
      margin-top: 10px;
      padding: 15px 0;
    }
  }
`

const DescDetailContainer = styled.div<{ animation: string }>`
  line-height: 1.8;
  ${({ animation }) => animation === 'in' ? 'animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;' : animation === 'out' ? 'animation: slide-out-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;' : ''}
  background-color: #EEE;
  padding: 20px 30px;
  margin: 30px 0 10px;

  @keyframes slide-in-top {
    0% {
      transform: scaleY(0);
      font-size: 1px;
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  @keyframes slide-out-top {
    0% {
      transform: scaleY(1);
      opacity: 1;
    }
    100% {
      transform: scaleY(0);
      font-size: 1px;
      opacity: 0;
    }
  }


  @media screen and (max-width: ${screen.mobile}) {
    font-size: 14px;
  }
`

const StrongPointListContainer = styled.div`
  padding: 80px 0;
  width: 70%;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${screen.mobile}) {
    width: 80%;
    flex-direction: column-reverse;
    padding: 0;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media screen and (max-width: ${screen.mobile}) {
      flex-direction: column-reverse;
      padding: 0;
    }
    
    & > ${PointDescContainer} {
      text-align: left;
      padding-left: 100px;
      padding-right: 0;
    
      @media screen and (max-width: ${screen.mobile}) {
        padding: 0;
      }
    }

    @media screen and (max-width: ${screen.mobile}) {
      flex-direction: column-reverse;
      padding: 0;
    }
  }

  &:not(:last-child) {
    border-bottom: 2px solid ${color.lightGray};

    @media screen and (max-width: ${screen.mobile}) {
      padding-bottom: 40px;
    }
  }

  & b {
    font-weight: 900;
  }
`

const PointVisualContainer = styled.div<{ src: string }>`
  width: 30%;
  height: 300px;
  padding: 0 40px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: ${screen.mobile}) {
    width: 80%;
    height: 280px;
    margin: 40px 0;
    display: flex;
    justify-content: center;
  }

  & > img { width: 100%; }
`

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

type iStrongPointList = {
  imgLink: string
  title: string
  desc: string | React.ReactNode
  detail: string | React.ReactNode
}

export const StrongPointList: React.FC<iStrongPointList> = ({
  imgLink,
  title,
  desc,
  detail
}) => {
  const [isReadMore, setIsReadMore] = React.useState(false)
  const [animation, setAnimation] = React.useState('in')

  const onOpenReadme = React.useCallback(
    () => {
      setAnimation('in')
      setIsReadMore(true)
    },
    []
  )

  const onCloseReadme = React.useCallback(
    async () => {
      setAnimation('out')
      await sleep(400)
      setIsReadMore(false)
    },
    []
  )

  return (
    <StrongPointListContainer>
      <PointDescContainer>
        <h3>{title}</h3>
        <div>
          {desc}
        </div>
        {
          isReadMore &&
          <DescDetailContainer animation={animation} onAnimationEnd={() => setAnimation('pending')}>
            {detail}
          </DescDetailContainer>
        }
        <button onClick={isReadMore ? onCloseReadme : onOpenReadme}>{isReadMore ? 'Sembunyikan Detail' : 'Baca Selengkapnya'}</button>
        
      </PointDescContainer>
      <PointVisualContainer src={imgLink}>
      </PointVisualContainer>
    </StrongPointListContainer>
  )
}
