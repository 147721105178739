import * as React from 'react'
import styled from 'styled-components'
import { color } from '../../utils'
import { screen } from '../../utils/screen'

const ProductCharContainer = styled.div`
  height: 80vh;
  background-image: url('https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/product_char/background.jpg');
  object-fit: cover;
  background-size: cover;
  background-position: top;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;

  & .section-title {
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: center;
  }

  
`
const CharList = styled.div`
  margin-bottom: 20px;
  line-height: 1.8;
  text-align: center;
  
  & > h4 {
    color: ${color.red};
    font-weight: bolder;
    font-size: 24px;
    margin-bottom: 10px;
  }

  & > p {
    font-size: 14px;
  }
`

const InnerContainer = styled.div`
  @media screen and (max-width: ${screen.mobile}) {
    width: 80%;
  }
`


export const ProductChar =  React.forwardRef((
  props,
  ref: any
) => {
  return (
    <ProductCharContainer ref={ref}>
      <InnerContainer>
        <h3 className="section-title">Exquisite Product</h3>
        <CharList>
          <h4>Kualitas Tinggi</h4>
          <p>Kami menyediakan Frame dan Lensa brand Jepang dengan kualitas tinggi, sehingga kami menjadi berani menjamin garansai produk secara maksimal </p>
        </CharList>

        <CharList>
          <h4>Pembeli adalah yang Terutama</h4>
          <p>Pelayanan yang terbaik dan responsif adalah komitmen kami untuk melayani pelanggan dengan maksimal. </p>
        </CharList>

        <CharList>
          <h4>Garansi tanpa syarat </h4>
          <p>Penggantian Frame dan Lensa tanpa syarat apabila terjadi kerusakan dan ketidaknyaman untuk setiap pembelian Frame lensa Megane.</p>
        </CharList>
      </InnerContainer>
    </ProductCharContainer>
  )
})
