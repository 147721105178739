import * as React from 'react'
import styled from 'styled-components'
import { color } from '../../utils'
import { RiWhatsappFill } from 'react-icons/ri'
import { GrMail } from 'react-icons/gr'
import { screen } from '../../utils/screen'

const SupportContainer = styled.div`
  width: 100vw;
  height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.white};
  text-align: center;
  line-height: 1.6;

  & > .section-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  & > .section-desc {
    width: 90%;
    font-size: 16px;
  }

  @media screen and (max-width: ${screen .mobile}) {
    height: 1100px;
  }
  
`

const SupportVisualContainer = styled.div`
  display: flex;
  justify-content: space-around;
  height: 45%;
  width: 40%;
  margin: 40px 0 80px 0;

  @media screen and (max-width: ${screen .mobile}) {
    width: 80%;
  }
`

const SupportVisual = styled.div`
  background-color: ${color.red};
  width: 40%;
  height: 100%;
  background-image: url('https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/support/woman_2.jpg');
  background-size: cover;
  background-position: center;

  @media screen and (max-width: ${screen .mobile}) {
    width: 100%;
  }

  &:first-child {
    background-image: url('https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/support/woman_1.jpg');
    margin-top: 40px;

    @media screen and (max-width: ${screen .mobile}) {
      display: none;
    }
  }
`

const ContactInfoContainer = styled.div`
  display: flex;

  @media screen and (max-width: ${screen .mobile}) {
    flex-direction: column;
  }
`

const StoreInfo = styled.div`
  padding-right: 60px;
  border-right: 2px solid ${color.lightGray};

  @media screen and (max-width: ${screen .mobile}) {
    padding: 0;
    border-right: 0;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px solid ${color.lightGray};
  }

  & > h4 {
    font-size: 20px;
  }

  & .item-container {
    display: flex;
    font-size: 12px;
    text-align: center;
    justify-content: center;

  }

  & .logo-container {
    margin-top: 20px;

    &:first-child {
      margin-right: 20px;
    }
  }

  & .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #FFF;
    margin-bottom: 8px;

    & > img { width: 70%; }
  }

  & a{
    text-decoration: none;
    color: inherit;
  }
`

const ContactInfo = styled.div`
  padding-left: 60px;

  @media screen and (max-width: ${screen .mobile}) {
    padding: 0;
  }

  & > h4 {
    font-size: 20px;
  }

  & .item-container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-align: center;
  }

  & .logo-container {
    display: flex;
    align-items: center;
    margin: 20px 20px 0 0;
    color: inherit;
    text-decoration: none;
  }

  & .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 0px;
    margin-right: 12px;
  } 
`

type iSupport = {
  ref?: React.MutableRefObject<HTMLDivElement | null>
}

export const Support = React.forwardRef((
  props,
  ref: any
) => {
  return (
    <SupportContainer ref={ref}>
      <h2 className="section-title">Layanan Pelanggan</h2>
      <div className="section-desc">Pelayanan Service Center yang Responsif<br/> saat Jam Kerja (08.00 - 17.00)</div>
      <SupportVisualContainer>
        <SupportVisual />
        <SupportVisual />
      </SupportVisualContainer>
      <ContactInfoContainer>
        <StoreInfo>
          <h4>Dapatkan Kami di</h4>
          <div className="item-container">
            <a href="https://www.tokopedia.com/meganeofficial" className="logo-container" target="_blank">
              <div className="image-container">
                <img src="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/support/tokopedia.png" alt="Logo Tokopedia" />
              </div>
              <span>Tokopedia</span>
            </a>

            <a href="https://shopee.co.id/shop/487039321/" className="logo-container" target="_blank">
              <div className="image-container">
                <img src="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/support/shopee.png" alt="Logo Shopee" />
              </div>
              <span>Shopee</span>
            </a>
          </div>
        </StoreInfo>

        <ContactInfo>
          <h4>Hubungi Kami</h4>
          <div className="item-container">
            <a className="logo-container" href="https://wa.me/628113509306" target="_blank">
              <div className="image-container">
                <RiWhatsappFill />
              </div>
              <span>082-501-3465</span>
            </a>

            <div className="logo-container">
              <div className="image-container">
                <GrMail />
              </div>
              <span>support@megane.co.id</span>
            </div>
          </div>
        </ContactInfo>
      </ContactInfoContainer>
    </SupportContainer>
  )
})

