import * as React from 'react'
import styled from 'styled-components'
import { color } from '../../utils'
import { screen } from '../../utils/screen'
import { StrongPointList } from '../atom/StrongPointList'

const StrongPointContainer = styled.div`
  width: 100vw;
  position: relative;
`

const OverviewContainer = styled.div`
  width: 100%;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;

    @media screen and (max-width: ${screen.mobile}) {
      position: relative;
      z-index: 1;
    }
  }
`

const OverviewDescContainer = styled.div`
  width: 50%;
  padding: 60px 0 0 20vw;
  color: ${color.white};
  line-height: 1.8;

  & > .section-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  @media screen and (max-width: ${screen.mobile}) {
    width: 80%;
    padding: 0 auto;
    color: ${color.black};

    & > .section-title {
      color: ${color.red};
    }
  }
`

const StrongPointListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30vh;

  @media screen and (max-width: ${screen.mobile}) {
    margin-top: 50px;
  }
`

export const StrongPoint = React.forwardRef((
  props,
  ref: any
) => {
  return (
    <StrongPointContainer ref={ref}>
      <OverviewContainer>
        <svg xmlns="http://www.w3.org/2000/svg" width="1924.088" height="530.973" viewBox="0 0 1924.088 530.973">
          <path id="Path_4" data-name="Path 4" d="M-12799.994,5155.133h1920.864v325.13s12.19,119.967-304.766,0-693.124,248.629-983.958,201.685-316.958-114.752-492.851-168.65-139.29-226.026-139.29-226.026Z" transform="translate(12803.155 -5155.133)" fill="#ef233c"/>
        </svg>

        <OverviewDescContainer>
          <h3 className="section-title">Garansi Terbaik dari Kami</h3>
          <div>
          Kami berkomitmen untuk memberikan Garansi purna jual yang terbaik, yang tidak di berikan oleh pihak manapun. Kami menjamin garansi atas kerusakan Frame dan kerusakan lensa (termasuk lensa gores/baret) serta garansi apabila pusing dan tidak nyaman saat di pakai.
          </div>
        </OverviewDescContainer>
      </OverviewContainer>

      <StrongPointListContainer>
        <StrongPointList
          title="Garansi Frame Rusak atau Patah"
          desc={<div>
            Kami yakin bahwa produk kacamata Megane telah melewati proses dan pengecekan kualitas yang seksama sebelum dipasarkan. kami juga percaya bahwa produk Megani mempunyai kualitas yang sangat baik. 
          </div>}
          detail={<>
            <b>Untuk itu, kami ingin setiap customer Megane juga percaya dan yakin akan kualitas Megane , dengan cara memberikan garansi sebagai berikut :</b><br/><br/>
            -Apabila sebelum satu tahun setelah pembelian terjadi kerusakan frame misal : frame patah, engsel lepas, cat mengelupas, cacat atau baret dan sebagianya. Kami akan ganti frame tersebut dengan yang baru.<br/><br/>
            - Apabila frame tersebut sudah habis terjual, kami akan ganti baru dengan yang sejenis dan mirip bentuk nya.<br/><br/>
            - Customer bebas memilih frame penggantinya sendiri, dan apabila frame yang dipilih harga nya lebih mahal dari sebelumnya,  customer hanya membayar selisih harga nya saja.<br/><br/>
            - Apabila frame yang di pilih perlu penggantian lensa, customers cukup membayar harga lensanya saja.<br/><br/>
            - Kami memberi garansi Frame rusak dengan ganti baru ini berlaku selama satu tahun satu kali. Dan ongkos kirim di tanggung oleh customer.<br/><br/>
            - Untuk proses klaim garansi dan informasi lebih lanjut bisa menghubungi CS Megane di no WA : 08113509306 atau email ke support@megane.co.id
          </>}
          imgLink="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/strong_point/broken_frame.png"
        />
        <StrongPointList
          title="Garansi Lensa Baret atau Rusak"
          desc={<div>
            Sebelum lensa di serahkan ke customer atau di kirim, Megane selalu melakukan Quality Control yang ketat untuk memastikan Lensa dan kacamata terkirim dalam keadaan yang baik. 
            Tetapi dengan berjalannya waktu setelah pemakaian, banyak sekali keluhan customer karena lensa nya baret/tergores atau retak. 
          </div>}

          detail={<>
            <b>Banyak sekali penyebab lensa kacamata menjadi rusak, buram, tergores atai retak , antara lain karena :</b><br /><br />
            - Cara mengelap lensa yang salah, misal mengunakan lap yg kotor atau baju yang sudah terkontaminasi dengan debu.<br /><br />
            - Mengelap lensa dalam keadaan kering, debu yang menempel di lensa, bisa membuat lensa menjadi tergores. Selalu membahasi lensa dengan air bersih mengalir atau cairan pembersih lensa sebelum di lap dengan kap bersih.<br /><br />
            - Meletakkan kacamata tidak pada tempatnya, atau di atas benda keras dengan posisi kacamarmta telungkup. Biasakan menaruh kacamata pada tempatnya.<br /><br />
            - Menaruh kacamata di tempat yang panas, seperti dashboard mobil,  dekat pemanas/kompor dsb, bisa membuat lensa kacamata menjadi retak.<br /><br />
            - Lensa kacamata juga bisa rusak akibat cairan kimia seperti, hair spray dsb. Untuk itu harap berhati2 supaya lensa kacamata kita awet. <br /><br />
            <br /><br />
            <b>Tetapi jangan kuatir untuk pembelian lensa megane, kami memberikan garansi lensa, sebagai berikut :</b><br /><br />
            - Lensa yang akan di garansikan harap di fotokan terlebih dahulu dan dikirim ke CS Megane di no WA : 08113509306 atau email ke support@megane.co.id.<br /><br />
            - Lensa yang rusak akan di ganti baru sesuai dengan type dan ukuran sebelumnya.<br /><br />
            - Garansi untuk merk selain megane, mengikuti ketentuan masing2 brand ( misal : essilor , rodenstock dll).<br /><br />
            - Kami memberi garansi lensa rusak dengan ganti baru ini berlaku selama satu tahun satu kali. Dan ongkos kirim di tanggung oleh customer.<br /><br />
            - Untuk proses klaim garansi dan informasi lebih lanjut bisa menghubungi CS Megane di no WA : 08113509306 atau email ke support@megane.co.id<br /><br />
          </>}
          imgLink="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/strong_point/logo_dizzy.png"
        />
        <StrongPointList
          title="Garansi Kenyamanan"
          desc={<div>
            Sering terjadi saat orang membeli kacamata minus atau plus, yang merasa kecewa karena saat dipakai, kacamata tersebut tidak nyaman dipakai. 
          </div>}
          detail={<>
            <b>Keluhan ketidaknyamanan antara lain :</b><br />
            - Pusing saat dipakai<br />
            - kurang jelas, penglihatan masih kabur<br />
            - Saat berjalan merasa bergelombang<br />
            - Sakit kepala sampai mual.<br />
            - Turun / naik tanggal merasa jauh.<br />
            - melihat objek seperti bengkok / tidak lurus<br />
            - dan sebagai nya. <br />
            

            <br /><br /><b>Tapi jangan kuatir kalau anda membeli kacamata Megane, Kami akan memberikan garansi kenyamanan sampai 1 bulan setelah barang di terima. Sbb : </b><br />
            - sampaikan keluhan anda ke CS Megane di no WA : 08113509306 atau email ke support@megane.co.id <br />
            - pastikan ukuran yang anda pesan sesuai dengan hasil periksaan yang benar, oleh sebab itu klaim di sertai resep dokter mata atau ukuran kacamata lama (kartu garansi sebelumnya).<br />
            - Kami akan garansi sesuai ukuran kacamata baru dari dokter mata atau kartu garansi tersebut.<br />
            - Garansi Kenyamanan eksklusive untuk lensa merk Megane Japan.<br />
            - Kami memberi garansi kenyamanan dengan ganti baru ini berlaku selama satu tahun satu kali. Dan ongkos kirim di tanggung oleh customer.<br />
            - untuk proses klaim garansi dan informasi lebih lanjut bisa menghubungi CS Megane di no WA : 08113509306 atau email ke support@megane.co.id<br />
          </>}
          imgLink="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/strong_point/broken_lens.png"
        />
        <StrongPointList
          title="Garansi Perawatan Kacamata"
          desc={<div>
            Selain ketiga Garansi di atas , yaitu : garansi frame oatah, garansi lensa baret/gores dan garansi kenyamanan, 
          </div>}
          detail={<>
            <b>Kami memberikan garansi perawatan kacamata yang meliputi :</b><br />
            - Penyetelan kacamata,  setelan dapat berubah karena pemakaian,  misal terbawa tidur, kedudukan dan lain sebagainya.<br />
            - pencucian kacamata,  dengan mengunakan mesin ultrasonik,  air reserve osmosis dan cairan pembersih. Sehingga kacamata dapat di bersihkan secara maksimal.<br />
            - penggantian dudukan hidung (nosepad) secara gratis , selama 5 tahun<br />
            - Ongkos kirim untuk garansi perawatan di tanggung oleh customer.<br />
            - untuk proses klaim garansi dan informasi lebih lanjut bisa menghubungi CS Megane di no WA : 08113509306 atau email ke support@megane.co.id<br />
          </>}
          imgLink="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/strong_point/gear_logo.png"
        />
        <StrongPointList
          title="Gratis pengiriman seluruh Indonesia"
          desc={<div>
            Setiap pembelian kacamata Megane,  kami akan berikan gratis biaya pengiriman ke seluruh Indonesia. 
          </div>}
          detail={<>
            - Pembelian hanya bisa di lakukan melalui marketplace : Tokopedia,  shopee,  bukalapak,  lazada dan blibli.<br />
            - Gratis ongkir akan otomatis dilakukan oleh sistem masing-masing Marketplace.<br />
            - Gratis ongkir hanya untuk pembelian frame dan lensa.<br />
            - untuk informasi lebih lanjut bisa menghubungi CS Megane di no WA : 08113509306 atau email ke support@megane.co.id
          </>}
          imgLink="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/strong_point/fast_truck.png"
        />

        <StrongPointList
          title="Discount untuk Peserta BPJS dan Asuransi Lainnya"
          desc={<div>
            Kabar gembira untuk pemegang kartu asuransi BPJS, Prudential, AXA, Manulife, Panin, AIA, Sequis, Allianz, BCA, Astra, Zurich, Mega, Marine, BNI, Lippo dsb.
            Anda akan mendapat potongan harga sampai 300rb untuk Setiap pembelian kacamata Megane, lengkap frame dan lensa. 
          </div>}
          detail={<>
            <b>Potongan harga 300rb untuk pembelian frame dan lensa dengan nilai senilai 2,5jt atau lebih. Dan potongan harga 10% untuk harga di bawah 2,5jt. </b><br />

            - Fotokan kartu asuransi anda dan kirim ke CS Megane di no WA : 08113509306 atau email ke support@megane.co.id. <br />
            - Anda akan di beri voucher untuk di input saat akan melakukan pembayaran di Marketplace.<br />
          </>}
          imgLink="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/strong_point/care_logo.png"
        />
      </StrongPointListContainer>
    </StrongPointContainer>
  )
})
