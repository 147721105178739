import React from 'react';
import './App.css';
import { HomePage } from './components/page/HomePage';

function App() {
  return (
    <HomePage />
  );
}

export default App;
