import * as React from 'react'
import styled from 'styled-components'
import { color } from '../../utils'
import { screen } from '../../utils/screen'

const FeatureHighlightContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.red};
  padding-bottom: 100px;
`

const AboutContainer = styled.div`
  margin-top: 200px;
  color: ${color.white};
  width: 60%;
  line-height: 1.8;
  text-align: center;

  @media screen and (max-width: ${screen.mobile}) {
    width: 80%;
  }

  & > h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  width: 70%;
  height: 350px;
  margin: 80px 0;

  @media screen and (max-width: ${screen.mobile}) {
    width: 80%;
    height: auto;
  }
`

const FeatureSection = styled.div`
  width: 70%;
  height: 100%;
  background-color: ${color.white};
  padding: 30px;

  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
  }

  & > .section-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 15px;
  }
`

const FeatureVisualContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;

  @media screen and (max-width: ${screen.mobile}) {
    flex-direction: column;
    height: auto;
  }

  & > .visual-section {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: ${screen.mobile}) {
      padding-top: 30px;
      margin-top: 30px;
    }

    &:not(:first-child) {
      border-left: 2px solid ${color.lightGray};
      padding-left: 50px;

      @media screen and (max-width: ${screen.mobile}) {
        border-left: 0;
        padding-left: 0;
        border-top: 2px solid ${color.lightGray};
      }
    }

    &:not(:last-child) {
      padding-right: 50px;

      @media screen and (max-width: ${screen.mobile}) {
        padding-right: 0;
      }
    }

    
    & > .image-container {
      display: flex;
      align-items: center;
      width: 120px;
      height: 120px;
      margin-bottom: 30px;
      
      & > img { width: 100%; }
    }
  }
`

const ImageSection = styled.div` 
  display: flex;
  align-items: center;
  width: 30%;
  height: 100%;
  background-color: ${color.gray};

  & > img {
    margin-left: -60px;
    width: 400px;
    transform: rotate(-15deg);
  }

  @media screen and (max-width: ${screen.mobile}) {
    display: none;
  }
`

export const FeatureHighlight = React.forwardRef((
  props,
  ref: any
) => {
  return (
    <FeatureHighlightContainer ref={ref}>
      <AboutContainer>
        <h2>Tentang Megane</h2>
        <p>
          Megane adalah merk kacamata brand Jepang yang menyediakan kacamata dan Lensa berkualitas tinggi, dengan harga yang terjangkau. 
          Kami menyadari bahwa kepuasan pelanggan adalah yang terutama, dan kami yakin bahwa semua produk Megane mempunyai kualitas yang baik,
          Oleh karena itu kami akan memberikan purna jual yang terbaik juga  untuk setiap customer Megane, dengan memberikan berbagai macam garansi dan diskon <br /> <br />
        </p>
      </AboutContainer>
      <InnerContainer>
        <FeatureSection>
          <h2 className="section-title">MEGANE</h2>
          <span className="section-detail">People Deserve Better</span>
          <FeatureVisualContainer>
            <div className="visual-section">
              <div className="image-container">
                <img src="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/feature_highlight/japan_logo.png" alt="Japan Logo"/>
              </div>
              <span>Brand of Japan</span>
            </div>

            <div className="visual-section">
              <div className="image-container">
                <img src="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/feature_highlight/price_logo.png" alt="Price Logo"/>
              </div>
              <span>Harga Terjangkau</span>
            </div>

            <div className="visual-section">
              <div className="image-container">
                <img src="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/feature_highlight/service_logo.png" alt="Service Logo"/>
              </div>
              <span>Pelayanan Terbaik</span>
            </div>
          </FeatureVisualContainer>
        </FeatureSection>
        <ImageSection>
          <img src="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/feature_highlight/gold_glasses.png" alt="Gold Glasses" />
        </ImageSection>
      </InnerContainer>
    </FeatureHighlightContainer>
  )
})
