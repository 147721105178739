import * as React from 'react'
import styled from 'styled-components'
import { color } from '../../utils'
import { screen } from '../../utils/screen'

const JumbotronContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: ${color.red};
  background: linear-gradient(90deg, ${color.red} 0%, ${color.red} 50%, ${color.white} 50%, ${color.white} 100%);
  overflow-x: hidden;

  @media screen and (max-width: ${screen.mobile}) {
    background: ${color.white};
    height: 80vh;
  }
`

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 200px;
  height: 100%;
  width: 34%;

  & > .text-graphic {
    position: absolute;
    top: 15%;
    text-align: center;
    font-size: 120px;
    letter-spacing: 20px;
    color: ${color.red};
    opacity: 0.3;

    @media screen and (min-width: ${screen.mobile}) {
      display: none;
    }
  }

  & > .title {
    font-size: 50px;
    font-weight: 900;
    letter-spacing: 15px;
    z-index: 99;
  }

  & > .mainImg {
    display: flex;
    align-items: center;
    height: 30%;
    padding: 0 50px;

    @media screen and (max-width: ${screen.mobile}) {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50vh;
    }

    & > img {
      height: 100%;

      @media screen and (max-width: ${screen.mobile}) {
        width: 100%;
        height: auto;
      }
    }
  }

  & > .scroll-down {
    font-size: 20px;
    margin-bottom: 60px;

    & > .scroll {
      color: ${color.white};
      margin-right: 10px;

      @media screen and (max-width: ${screen.mobile}) {
        color: ${color.black};
      }
    }

    & > .down {
      color: ${color.red};
      @media screen and (max-width: ${screen.mobile}) {
        color: ${color.black};
      }
    }
  }

  @media screen and (max-width: ${screen.mobile}) {
    width: 100%;
  }

`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 33%;
  color: ${color.white};

  & > .sloganLine {
    height: 60%;
    margin: 0 40px;

    & > svg { height: 100%; }
  }

  & > .slogan {
    line-height: 3;
    letter-spacing: 4px;
    font-size: 24px;
  }

  @media screen and (max-width: ${screen.mobile}) {
    display: none;
  }
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 33%;

  & > .text-graphic {
    margin-right: 40px;
    font-size: 170px;
    letter-spacing: 70px;
    writing-mode: vertical-rl;
    text-orientation: upright;
    color: ${color.lightGray};
  }

  @media screen and (max-width: ${screen.mobile}) {
    display: none;
  }
`


export const Jumbotron = React.forwardRef((
  props,
  ref: any
) => {
  return (
    <JumbotronContainer ref={ref}>
      <RightContainer>
        <div className="sloganLine">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="677" viewBox="0 0 13 677">
            <g id="Accesories_2" data-name="Accesories 2" transform="translate(-79 -275)">
              <rect id="Rectangle_4" data-name="Rectangle 4" width="13" height="623" rx="6.5" transform="translate(79 275)" fill="#fff"/>
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(79 912)" fill="#fff"/>
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="6.5" cy="6.5" r="6.5" transform="translate(79 939)" fill="#fff"/>
            </g>
          </svg>
        </div>
        
        <div className="slogan">
          <div>People</div>
          <div>Deserve</div>
          <div>Better</div>
        </div>
      </RightContainer>

      <CenterContainer>
        <div className="title">
          MEGANE
        </div>
        <div className="text-graphic">
          メガネ
        </div>
        <div className="mainImg">
          <img
            src="https://megane-lp-data-bucket.s3.ap-northeast-1.amazonaws.com/jumbotron/main_frame_5.png"
            alt="White Big Glasses"
          />
        </div>
        <div className="scroll-down">
          <span className="scroll">Scroll</span>
          <span className="down">Down</span>
        </div>
      </CenterContainer>

      <LeftContainer>
        <div className="text-graphic">
          メガネ
        </div> 
      </LeftContainer>
    </JumbotronContainer>
  )
})
